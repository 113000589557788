import React, { useState, useEffect } from 'react';
import axios from '../services/axios';
import Slider from 'react-slick';
import './ListingWidgetRegular.css';
import { Link } from 'react-router-dom';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// Custom arrow components positioned at the top-right
const NextArrow = (props) => {
  const { onClick } = props;
  return (
    <div className="listing-regular-arrow listing-regular-next-arrow" onClick={onClick}>
      →
    </div>
  );
};

const PrevArrow = (props) => {
  const { onClick } = props;
  return (
    <div className="listing-regular-arrow listing-regular-prev-arrow" onClick={onClick}>
      ←
    </div>
  );
};

const ListingWidgetRegular = () => {
  const [listings, setListings] = useState([]);

  useEffect(() => {
    const fetchListings = async () => {
      try {
        const response = await axios.post('/listings/bulk');
        setListings(response.data);
      } catch (error) {
        console.error('Error fetching listings:', error);
      }
    };

    fetchListings();
  }, []);

  const formatPrice = (price, isRental) => {
    const formattedPrice = `$${Number(price).toLocaleString()}`;
    return isRental ? `${formattedPrice}/month` : formattedPrice;
  };

  if (!listings.length) {
    return <div>Loading...</div>;
  }

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className='regular-listing-slider-container'>
      <h2>Modern Solution Featured Listings</h2>

    </div>
  );
};

export default ListingWidgetRegular;
