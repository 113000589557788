import React from 'react';
import './ServicesTable.css'; // Create and import the CSS file
import msrlogo from '../assets/images/ms.webp'; // Import the logo

const ServicesTable = () => {
  const services = [
    "For Sale Sign", "MLS", "Realtor.ca", "Specialized Agent",
    "Marketing Analysis & Evaluation", "Photography & Brochure",
    "Social Media Marketing", "Direct Mail Campaign", "Email Campaign",
    "Constant Buyer Prospecting", "Negotiation", "Dedicated Realtor 24/7",
    "Third-Party Contacts", "Global Reach", "Open Houses",
    "Free Staging Consultant", "Commission Fees"
  ];

  const totalServices = services.length;

  return (
    <div className="unique-services-table">
      <table>
        <thead>
          <tr>
            <th></th>
            <th style={{ lineHeight: '2.7vw', color: '#1a1a1a', fontWeight: 'bold' }}>
              <img src={msrlogo} alt="Modern Solution Realty Logo" style={{ width: '100px', marginBottom: '10px' }} /> <br></br> {/* Added Logo */}
              Modern Solution Realty
            </th>
            <th style={{ lineHeight: '2.7vw', color: '#1a1a1a', fontWeight: 'bold' }}>
              Typical Brokerage <br />(Outdated Commission)
            </th>
          </tr>
        </thead>
        <tbody>
          {services.map((service, index) => (
            <tr key={index}>
              <td>{service}</td>
              <td>
                {index === totalServices - 1 ? '$10,000*' : <i className="fa fa-check unique-checkmark-logo"></i>}
              </td>
              <td>
                {index === totalServices - 1 ? '$25,000*' : (
                  service === "Open Houses" || service === "Free Staging Consultant" || index < totalServices - 3 ?
                    <i className="fa fa-check unique-checkmark-logo"></i> :
                    <i className="fa fa-times unique-checkmark-logo"></i>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ServicesTable;
