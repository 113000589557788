import React, { useEffect, useState } from 'react';
import './Buy.css';
import bgImage from '../assets/images/sell.webp'; // New background image
import GoogleReviews from './GoogleReviews';
import ContactForm from './ContactForm';
import ListingWidget from './ListingWidget'; // Ensure this path is correct
import Footer from './Footer'; // Import the Footer component
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const Buy = () => {
  const [savings, setSavings] = useState(0);
  const [openAccordion, setOpenAccordion] = useState(null);
  const [heroImageLoaded, setHeroImageLoaded] = useState(false);

  useEffect(() => {
    const target = 1100000;
    let current = 0;
    const increment = target / 100;

    const counter = setInterval(() => {
      current += increment;
      if (current >= target) {
        current = target;
        clearInterval(counter);
      }
      setSavings(Math.floor(current));
    }, 20);

    return () => clearInterval(counter);
  }, []);

  const toggleAccordion = (index) => {
    setOpenAccordion(openAccordion === index ? null : index);
  };

  const accordionData = [
    {
      title: 'We help you plan out the journey',
      description:
        'Our experienced agents are committed to expertly navigating you through every phase of the property-buying journey.',
    },
    {
      title: 'We go see some properties together',
      description:
        'Our agents are dedicated to providing guidance without any pressure to purchase.',
    },
    {
      title: 'We negotiate to get the best price',
      description:
        'Rest assured, we are committed to tirelessly negotiating on your behalf to secure the most favorable price, terms, and conditions possible.',
    },
    {
      title: 'We help you to get your mortgage commitment',
      description:
        'Mere mortgage approval isn’t sufficient for closing; secure your loan by obtaining a mortgage commitment before the closing date.',
    },
    {
      title: 'We search extensively',
      description:
        'We conduct an extensive search of all MLS® and exclusive listings we have access to offline.',
    },
    {
      title: 'We consult on each listing with you',
      description:
        'Get expert guidance on property buying with us! Our agents will assist you in evaluating the pros and cons of each opportunity.',
    },
    {
      title: 'We help hire an expert home inspector',
      description:
        'Our agents review the inspection report with you fully before you make your final decision.',
    },
    {
      title: 'We help you to finalize your home purchase, hassle-free',
      description:
        'After solidifying your property purchase, there’s still plenty of groundwork required to finalize the closing process.',
    },
  ];

  const handleHeroImageLoad = () => {
    setHeroImageLoaded(true);
  };

  return (
    <div className='buying-process-container'>
      <Helmet>
        <title>Modern Solution Realty - Buy</title>
      </Helmet>
      <div className='buying-process'>
        <div
          id='rebate'
          className='hero-section'
          style={{
            backgroundImage: `url(${bgImage})`,
            position: 'relative',
          }}
        >
          <div className='hero-overlay' /> {/* Overlay added here */}
          <img
            src={bgImage}
            alt='Hero'
            onLoad={handleHeroImageLoad}
            style={{ display: 'none' }}
          />
          <p>
            Receive $5,000 from our commission for your new journey. When you
            buy a property, the seller pays a commission that’s split between
            their agent and yours. We give you $5,000 from our fee.
          </p>
          <p className='small-text'>
            * Applicable to properties $500,000 or more
          </p>
          <div className='counter'>
            <h2>${savings.toLocaleString()}+</h2>
            <p>in savings for our clients</p>
          </div>
        </div>

        {heroImageLoaded && (
          <>
            <GoogleReviews />
            <div id='save-more' className='two-column-section'>
              <div className='left-column'>
                <h2>Save More with Modern Solution</h2>
                <h3>
                  The real estate industry has changed. Here’s how we can pass
                  the savings on to you.
                </h3>
                <div className='subcategory-divider'></div>
                <div className='accordion'>
                  {accordionData.map((item, index) => (
                    <div key={index} className='accordion-item'>
                      <div className='accordion-header'>
                        <div className='accordion-title'>{item.title}</div>
                        <div
                          className='accordion-arrow'
                          onClick={() => toggleAccordion(index)}
                        >
                          {openAccordion === index ? '↑' : '↓'}
                        </div>
                      </div>
                      {openAccordion === index && (
                        <div className='accordion-content'>
                          <p>{item.description}</p>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
              <div className='right-column'>
                <h2 className='contact-form-title'>
                  Buy With Us Today. Talk to an Agent.
                </h2>
                <h3>
                  Use the form below to send us an email. We'll get back to you
                  as soon as we can.
                </h3>
                <div className='subcategory-divider'></div>
                <div className='contact-form'>
                  <ContactForm />
                </div>
              </div>
            </div>

            <ListingWidget />

            <div id='buyer-rebate' className='new-two-column-section'>
              <div className='new-left-column'>
                <h2>
                  <span className='highlighted-text'>Buyer Rebate</span>
                </h2>
                <p>
                  <strong>Get to the Bank</strong>
                </p>
                <div className='subcategory-divider'></div>
                <p>
                  A crucial and straightforward step in the property buying
                  process involves meeting with a mortgage broker or bank. They
                  will inform you precisely how much you can comfortably afford,
                  provide mortgage payment details, and address any questions
                  you may have.
                  <br />
                  <br />
                  This ensures you have the confidence and readiness necessary
                  for planning your purchase.
                </p>
                <p>
                  <strong>Wants & Needs</strong>
                </p>
                <div className='subcategory-divider'></div>
                <p>
                  At Modern Solution Realty, we prioritize qualifying your needs
                  to ensure no time is wasted on properties that don’t align
                  with your criteria. This is where we delve deep into
                  understanding your preferences for your future property,
                  including what excites you and what doesn’t. Everything
                  matters, from your preferred neighborhood and essential
                  amenities to proximity to schools, commute times, and highway
                  accessibility.
                </p>
                <p>
                  <strong>Purchase Price</strong>
                </p>
                <div className='subcategory-divider'></div>
                <p>
                  Listing prices are merely a starting point; they don’t
                  necessarily reflect the true value or what you’ll ultimately
                  pay for a property. At our agency, we conduct thorough
                  research using data and analytical trends—creating a
                  Comparative Market Analysis (CMA) as if we were listing the
                  property ourselves. This analysis provides an accurate
                  assessment of what the property is likely to sell for,
                  ensuring you have a clear understanding of its true value in
                  the current market.
                </p>
              </div>
              <div className='new-right-column'>
                {/* The background image will be applied via CSS */}
              </div>
            </div>

            <div id='making-offer' className='offer-section'>
              <h2>
                <span className='highlighted-text'>Making an Offer</span>
              </h2>
              <div className='subcategory-divider'></div>
              <p>
                You have fallen in love and would like to make an offer. We will
                draw up all the necessary documents and explain to you all the
                aspects of the offer that you can include or drop to help your
                chances of the offer being accepted.
              </p>
              <p>
                In addition to price, there are other critical factors that can
                impact the acceptance of your offer—these are known as
                conditions. Some examples include making your offer conditional
                on obtaining financing, conducting a thorough inspection, and
                having your lawyer review the status certificate (if purchasing
                a condo).
              </p>
              <p>
                Whether your offer stands alone or is among multiple offers,
                there are strategies to enhance its appeal, which we’ll guide
                you through.
              </p>
              <p>
                When presented with an offer, the seller will have three options
                to consider:
              </p>
              <ul className='offer-options'>
                <li className='bubble'>
                  1. The seller can accept the offer as is.
                </li>
                <li className='bubble'>2. The seller can reject the offer.</li>
                <li className='bubble'>3. The seller can counter the offer.</li>
              </ul>
              <p>
                The most common scenario is the third option, where negotiation
                becomes paramount. Our seasoned real estate agents will take the
                lead in negotiating with the seller’s agent, leveraging their
                years of experience to navigate the process effectively. We’ll
                engage in back-and-forth discussions until you are completely
                satisfied with both the price and the other terms outlined in
                the agreement of purchase and sale.
              </p>
            </div>

            <div id='deposit' className='new-two-column-section'>
              <div className='new-left-column'>
                <h2>
                  <span className='highlighted-text'>The Deposit</span>
                </h2>
                <div className='subcategory-divider'></div>
                <p>
                  Once the terms of the agreement have been mutually accepted,
                  as the buyer, you’ll be required to provide a certified cheque
                  or bank draft for the deposit amount. This deposit will be
                  held in the selling brokerage’s trust account. In the event
                  that you’re unable to fulfill a condition or unwilling to
                  accept a deficiency uncovered during inspections, the deal
                  will be rendered null and void, and the deposit will be
                  returned to you, the buyer, in full.
                </p>
                <p>
                  If all conditions are met without any issues, the deal will
                  firm up, and the deposit will remain in the selling
                  brokerage’s trust until the closing date.
                </p>
              </div>
              <div className='new-right-column'>
                {/* The background image will be applied via CSS */}
              </div>
            </div>

            <div id='conditions' className='conditions-section'>
              <h2>
                <span className='highlighted-text'>Conditions</span>
              </h2>
              <div className='subcategory-divider'></div>
              <p>
                The condition of financing is usually quick to fulfill as most
                buyers have already seen a mortgage broker and have been
                pre-approved. You will just need to visit your mortgage broker
                and they will begin the final approval process. The common range
                of time for this condition is 5-7 business days.
              </p>
              <p>
                The inspection will take place on a pre-agreed upon date by both
                parties and will run roughly 2-3 hours. We will accompany you
                and the inspector to the property while he conducts his/her
                inspection. Following the inspection you will be handed a
                summary of the findings, at which point 4 things can happen:
              </p>
              <ul className='conditions-list'>
                <li className='condition-item'>
                  <div className='condition-icon'>1</div>
                  <div className='condition-text'>
                    All will be good and you will sign the waiver.
                  </div>
                </li>
                <li className='condition-item'>
                  <div className='condition-icon'>2</div>
                  <div className='condition-text'>
                    There might be issues you would like the seller to fix.
                  </div>
                </li>
                <li className='condition-item'>
                  <div className='condition-icon'>3</div>
                  <div className='condition-text'>
                    You will ask for money back which has a few variables to
                    consider and we will walk you through all of them at that
                    time.
                  </div>
                </li>
                <li className='condition-item'>
                  <div className='condition-icon'>4</div>
                  <div className='condition-text'>
                    There is an issue that you are unwilling to accept and are
                    unwilling to move forward with the sale, in which case the
                    deposit will be returned to you in full.
                  </div>
                </li>
              </ul>
              <p>
                In the case of a condo, The Status Certificate will be ordered
                by the seller at their own expense ($100) and usually takes
                between 6-10 business day to receive. The document will then be
                reviewed by the buyer’s lawyer which can take 1-3 business days.
                This condition is for the benefit of the buyer and if everything
                in the document is satisfactory to you, you can go ahead and
                sign the waiver.
              </p>
              <p>
                Once all the conditions have been met and the waiver/waivers
                have been signed the deal becomes firm and you have officially
                bought a home!
              </p>
            </div>

            <div id='revisits' className='new-two-column-section'>
              <div className='new-left-column'>
                <div className='subsection-box'>
                  <h2>Revisits</h2>
                  <div className='left-divider'></div>
                  <p>
                    In most cases, offers include provisions for the buyer to
                    revisit the property one or two more times before the
                    closing date. These visits afford you the opportunity to
                    take measurements of specific areas, enabling you to start
                    planning how you’ll utilize the space. Additionally, you can
                    bring your family through to get their input, and you’ll
                    have the chance to ensure that everything is in good working
                    order before finalizing the purchase.
                  </p>
                </div>
                <div className='subsection-box'>
                  <h2>Moving Day</h2>
                  <div className='left-divider'></div>
                  <p>
                    Moving day can indeed be stressful and exhausting, making it
                    a wise idea to consider hiring professional movers. While
                    they do come at a cost, our experience suggests that for
                    larger moves, the investment is often worthwhile.
                    Additionally, if you’re moving into a condo, it’s important
                    to remember to book the elevator in advance to streamline
                    the moving process.
                  </p>
                </div>
                <div className='subsection-box'>
                  <h2>Mortgage Details</h2>
                  <div className='left-divider'></div>
                  <p>
                    During your final meeting with your mortgage broker, they
                    will review the details of your mortgage agreement with you.
                    It’s important to ask numerous questions until you have a
                    thorough understanding of what will be expected of you. Once
                    you feel completely comfortable with the terms outlined, you
                    will proceed to sign off on the mortgage agreement. This
                    ensures that you enter into the mortgage arrangement with
                    confidence and clarity regarding your obligations.
                  </p>
                </div>
                <div className='subsection-box'>
                  <h2>Visit your Lawyer</h2>
                  <div className='left-divider'></div>
                  <p>
                    Before the closing date, you’ll meet with your lawyer to
                    finalize the financial aspects of the purchase. During this
                    meeting, you’ll provide a check for the balance of the
                    purchase price, which will deduct the mortgage amount and
                    deposit, but include additional expenses such as the land
                    transfer tax (LTT) and any other adjustments. Your lawyer
                    will inform you of these adjustments, which may include
                    reimbursing the seller for maintenance fees paid beyond the
                    closing date. This ensures that all financial aspects of the
                    transaction are properly settled before the closing process
                    is completed.
                  </p>
                </div>
                <div className='subsection-box'>
                  <h2>Closing Day</h2>
                  <div className='left-divider'></div>
                  <p>
                    The cheque has been cleared and the funds transferred. Now
                    all you need to do is meet with your lawyer one final time
                    to pick up the keys to your new property!
                  </p>
                </div>
              </div>
              <div className='new-right-column'>
                {/* The background image will be applied via CSS */}
              </div>
            </div>

            <div
              id='congratulations'
              className='congratulations-section parallax-section'
            >
              <div className='overlay'>
                <div className='need-sell-content'>
                  <h2>
                    <span className='highlighted-text'>
                      Congratulations you bought a home!
                    </span>
                  </h2>
                  <div className='divider'></div>
                  <p>
                    After your purchase has closed Modern Solution Realty will
                    receive a commission from the listing brokerage (usually 1-2
                    weeks after closing), at this time we will send you a cheque
                    for your rebate less the transfer fee of $50.00.
                  </p>
                </div>
              </div>
            </div>

            <div className='we-sell-you-save'>
              <div className='left-column'>
                <h2>
                  Browse Listings<span className='partners-text'> on MLS</span>
                </h2>
                <div className='divider'></div>
                <p>
                  Discover your dream home with Modern Solution Realty’s
                  comprehensive MLS listings. Our platform provides you with the
                  most up-to-date and accurate information on available
                  properties, ensuring you have access to the best real estate
                  opportunities in the market.
                </p>
                <p>
                  At Modern Solution Realty, we understand that finding the
                  perfect home is more than just a transaction; it’s a journey.
                  Our dedicated team of real estate professionals is committed
                  to guiding you every step of the way, offering expert advice
                  and personalized service. With our extensive knowledge of the
                  local market and cutting-edge tools, we empower you to make
                  informed decisions and confidently navigate the complexities
                  of buying or selling a home. Start your search today and let
                  us help you turn your real estate dreams into reality.
                </p>

                <Link to='/contact'>
                  <button className='list-button'>List Your Home For 1%</button>
                </Link>
              </div>
              <div className='new-right-column'>
                {/* The background image will be applied via CSS */}
              </div>
            </div>

            <div id='sell' className='need-to-sell-section parallax-section'>
              <div className='overlay'>
                <div className='need-sell-content'>
                  <h2>
                    <span className='highlighted-text'>
                      Need to <span className='partners-text'>Sell First?</span>
                    </span>
                  </h2>
                  <div className='divider'></div>
                  <p>
                    Start Saving Today, List with 1%. Find out how our sellers
                    saved an average of $18,000.
                  </p>

                  <Link to='/contact'>
                    <button className='list-button'>List With Us For 1%</button>
                  </Link>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <Footer /> {/* Footer component here */}
    </div>
  );
};

export default Buy;
