import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom'; // <-- Add this line
import SEO from './SEO';
import GoogleReviews from './GoogleReviews';
import CommissionCalculator from './CommissionCalculator';
import ContactForm from './ContactForm'; // Import ContactForm component
import { AiFillStar } from 'react-icons/ai'; 
import { FaPlay } from 'react-icons/fa'; // Example for Play Icon
import googleLogo from '../assets/images/google-logo.webp'; 
import Footer from './Footer'; 
import './Home.css';
import ListingWidgetRegular from './ListingWidgetRegular';
import ListingWidget from './ListingWidget';
import Listings from './Listings';
import Slider from 'react-slick'; // Import Slider for property listing display
import icon1 from '../assets/images/icon-1.webp';
import icon2 from '../assets/images/icon-2.webp';
import icon3 from '../assets/images/icon-3.webp';
import icon4 from '../assets/images/icon-4.webp';
import icon5 from '../assets/images/icon-5.webp';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Testimonials from './Testimonials';
import axios from '../services/axios';

const Home = () => {
  const [propertyListings, setPropertyListings] = useState([]); // State for property listings
  const [isVideoPlaying, setIsVideoPlaying] = useState(false); // To manage play button visibility
  const [showContactForm, setShowContactForm] = useState(false); // State to manage button visibility
  const videoRef = useRef(null); // Reference to the video element

  // Fetch property listings (this simulates your data fetching for properties)
  useEffect(() => {
    const fetchListings = async () => {
      const response = await axios.get('/listings'); // Replace with your actual API endpoint
      const data = response;
      setPropertyListings(data);
    };
    
    fetchListings();
  }, []);

  // Slider settings for the property listings
  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 6000, 
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000, 
    cssEase: 'linear',
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    const observerOptions = {
      threshold: 0.1,
    };

    const handleIntersection = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
          observer.unobserve(entry.target);
        }
      });
    };

    const observer = new IntersectionObserver(
      handleIntersection,
      observerOptions
    );

    const elementsToAnimate = document.querySelectorAll('.animate-on-scroll');
    elementsToAnimate.forEach((element) => {
      observer.observe(element);
    });

    return () => {
      elementsToAnimate.forEach((element) => {
        observer.unobserve(element);
      });
    };
  }, []);

  const counters = [
    { value: '1%', text: 'Listing for Sellers', icon: icon1 },
    { value: '$5,000', text: 'Cashback for Buyers', icon: icon2 },
    { value: '2,100+', text: 'Properties Sold', icon: icon3 },
    { value: '$1,100,000+', text: 'Buyers Received in Cashback', icon: icon4 },
    { value: '$20,150,000+', text: 'Sellers Saved on Commission', icon: icon5 },
  ];

  // Function to play video and hide the play button
  const handlePlayVideo = () => {
    console.log("videoRef.current:" + videoRef.current);
    if (videoRef.current) {
      videoRef.current.play();
      setIsVideoPlaying(true); // Hide the play button
    }
  };

  const handleButtonClick = () => {
    setShowContactForm(true); // Hide buttons and show contact form
  };

  return (
    <div className="home-page">
      <SEO
        title="Sell for 1% Commission | Low Commission Real Estate | 1 Percent List Realty"
        description="Sell your home for just 1% commission with Modern Solution. Enjoy low commission real estate services and get $5000 cashback when you buy. Call now!"
        keywords={[
          '1% commission real estate',
          'low commission real estate',
          'sell for 1 percent',
          '1 Percent List Realty',
          '$5000 cashback',
          'Modern Solution Realty',
        ]}
        ogImage="https://modernsolution.ca/homepage-image.jpg"
        url="https://modernsolution.ca/"
      />

      {/* Video Section */}
      <div className="fullwidth-video-container">
        <div className="video-container">
          {/* Play Button - Only visible when video is not playing */}
          {!isVideoPlaying && (
            <div className="unique-play-button" onClick={handlePlayVideo}>
              <FaPlay className="unique-play-icon" />
            </div>
          )}
          {/* Video Element */}
          <video
            ref={videoRef}
            width="100%"
            height="auto"
            controls
            playsInline={true}
            preload="metadata"  // Prevents mobile browsers from preloading the video
            poster={require('../assets/images/video-poster.png')}
            onPlay={() => setIsVideoPlaying(true)} // Ensure play button disappears when playing
            onPause={() => setIsVideoPlaying(false)} // Optionally handle pause
          >
            <source src={require('../assets/videos/video.mp4')} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>


      {/* New Section */}
      <div className="home-page-under-video-heading">
        <h4 className="supertext">OUR PRODUCTS</h4> {/* Added supertext */}
        <h2 className="section-heading">Modern Solutions For Every Scenario</h2>
      </div>

      <div className="home-page-blue-section">
        <div className="home-page-column">
          <div className="home-page-icon-text-container">
            <div className="home-page-blue-icon-square">
              <i className="fas fa-home home-page-icon" style={{ color: '#5a8ca5' }}></i>
            </div>
            <h2><em>Buying a Home?</em></h2>
          </div>
          <p>Buy a home with us and receive $5,000 Cashback.</p>
          <Link to="/buy">
            <button className="home-page-cta-button">Get $5,000 Cashback</button>
          </Link>
        </div>
        <div className="home-page-column">
          <div className="home-page-icon-text-container">
            <div className="home-page-blue-icon-square">
              <i className="fas fa-sign home-page-icon" style={{ color: '#5a8ca5' }}></i>
            </div>
            <h2><em>Selling Your Home?</em></h2>
          </div>
          <p>Find out how we can help you save with our 1% commission services.</p>
          <Link to="/sell">
            <button className="home-page-cta-button">List Your Home for 1%</button>
          </Link>
        </div>
      </div>

      <div className="two-column-section">
        <div className="left-column">
          {/* Commission Calculator */}
          <div className="white-container">
            <div className="calculator-headings">
              <h2>On Average, our clients saved <span style={{ color: '#9cdcf8' }}>$18,000</span>.</h2>
              <h3>How Much Will You Save?</h3>
            </div>
            <CommissionCalculator />
          </div>

          {/* Add Star Ratings and Review Count */}
<a href="https://g.co/kgs/XYNyUNZ" target="_blank" rel="noopener noreferrer" className="review-header-link">
  <div className="review-header">
    <div className="stars-row">
      <AiFillStar className="star-icon" />
      <AiFillStar className="star-icon" />
      <AiFillStar className="star-icon" />
      <AiFillStar className="star-icon" />
      <AiFillStar className="star-icon" />
    </div>
    <p className="review-count">Based on 222 Reviews</p>
    <img src={googleLogo} alt="Google Logo" className="google-logo" />
  </div>
</a>


          {/* Testimonials */}
          <div className="home-page-testimonials">
            <Testimonials />
          </div>

          {/* Counter Section */}

          <div className="counter-section">
          <h2>Results</h2>
            <div className="counter-row">
              {counters.slice(0, 3).map((counter, index) => (
                <div key={index} className="counter-box">
                  <img src={counter.icon} alt={`icon-${index + 1}`} className="counter-icon" />
                  <h3>{counter.value}</h3>
                  <p>{counter.text}</p>
                </div>
              ))}
            </div>
            <div className="counter-row">
              {counters.slice(3, 5).map((counter, index) => (
                <div key={index} className="counter-box">
                  <img src={counter.icon} alt={`icon-${index + 1}`} className="counter-icon" />
                  <h3>{counter.value}</h3>
                  <p>{counter.text}</p>
                </div>
              ))}
            </div>
          </div>

          {/* Show contact form after button click */}
          {!showContactForm ? (
            <div className="buttons-section">
              <button className="white-button" onClick={handleButtonClick}>I'm Buying</button>
              <button className="white-button" onClick={handleButtonClick}>I'm Selling</button>
              <button className="white-button" onClick={handleButtonClick}>I'm Buying & Selling</button>
              <button className="white-button" onClick={handleButtonClick}>Contact Us</button>
            </div>
          ) : (
            <ContactForm /> // Load ContactForm component
          )}
        </div>
        
        <ListingWidget />
        <div className="home-page-lets-talk" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/lets-talk.jpg)` }}>
  <h2>Let's Talk</h2>
  <p>When you're ready, a Modern Solution Realty agent is available to help you and answer any questions you may have.</p>
  <button className="home-page-cta-button" onClick={() => window.location.href = 'tel:(905) 897-5000'}>
  Give us a call
</button>

</div>

{/* Map Section */}
<div className="home-page-map-section">
  <img src={`${process.env.PUBLIC_URL}/map.png`} alt="Location map" className="home-page-map" />
</div>

      </div>

      <Footer />
    </div>
  );
};

export default Home;
