import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation, useParams, useNavigate } from 'react-router-dom';
import Navbar from './components/Navbar';
import NavbarHome from './components/NavbarHome';
import TopBar from './components/TopBar';
import Home from './components/Home';
import Buy from './components/Buy';
import Sell from './components/Sell';
import Search from './components/Search';
import Articles from './components/Articles';
import ArticleDetail from './components/ArticleDetail';
import Contact from './components/Contact';
import CommissionExplained from './components/CommissionExplained';
import ThankYou from './components/ThankYou';
import SellingPage from './components/SellingPage';
import Listings from './components/Listings';
import ListingDetail from './components/ListingDetail';
import Wishlist from './components/Wishlist';
import Login from './components/Login';
import Register from './components/Register';
import Users from './components/Users';
import ForgotPassword from './components/ForgotPassword';
import AddArticle from './components/AddArticle';
import ActivateAccount from "./components/ActivateAccount";
import useScroll from './useScroll';
import { AuthProvider } from './context/AuthContext';
import './App.css';
import CityPages from './components/CityPages';


// List of valid cities
const validCities = [
  'Adjala-Tosorontio', 'Ajax', 'Aurora', 'Barrie', 'Brampton', 'Brock', 'Burlington',
  'Caledon', 'Cambridge', 'Centre Wellington', 'Chatsworth', 'Clarington', 'Collingwood',
  'East Gwillimbury', 'East Luther Grand Valley', 'Erin', 'Essa', 'Fort Erie',
  'Galway-Cavendish and Harvey', 'Gananoque', 'Georgian Bay', 'Georgian Bluffs',
  'Georgina', 'Grimsby', 'Guelph', 'Guelph/Eramosa', 'Haldimand', 'Halton Hills',
  'Hamilton', 'Innisfil', 'Kawartha Lakes', 'King', 'Kingston', 'Kitchener',
  'Lincoln', 'London', 'Markham', 'Midland', 'Milton', 'Mississauga', 'New Tecumseth',
  'Newmarket', 'Niagara Falls', 'Niagara-on-the-Lake', 'Oakville', 'Orangeville',
  'Orillia', 'Oro-Medonte', 'Oshawa', 'Ottawa', 'Owen Sound', 'Penetanguishene',
  'Peterborough', 'Pickering', 'Port Colborne', 'Scugog', 'Severn', 'Shelburne',
  'Tiny', 'Toronto', 'Vaughan', 'Wainfleet', 'Wasaga Beach', 'Waterloo', 'Welland',
  'Whitby', 'Whitchurch-Stouffville', 'Woolwich'
];

// Component to handle city validation inside App.js
const CityPageRoute = () => {
  const { city } = useParams();
  const navigate = useNavigate();

  // Convert city param to capitalized version (handle city names with hyphens for multi-word cities)
  const cityCapitalized = city
    .split('-')
    .map(part => part.charAt(0).toUpperCase() + part.slice(1).toLowerCase())
    .join(' ');

  // Validate if the city is in the list of valid cities
  if (!validCities.includes(cityCapitalized)) {
    navigate('/'); // Redirect to home page if city is invalid
    return null; // Prevent rendering anything before redirecting
  }

  return <Listings city={cityCapitalized} />; // Render Listings component with city passed as prop
};

function AppContent() {
  useScroll();
  const location = useLocation();

  return (
    <div className="App">
      {/* Conditionally render TopBar based on the current route */}
      {location.pathname !== '/' && <TopBar />}
      {/* Conditionally render NavbarHome or Navbar based on the current route */}
      {location.pathname === '/' ? <NavbarHome /> : <Navbar />}
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/buy' element={<Buy />} />
        <Route path='/sell' element={<Sell />} />
        <Route path='/search' element={<Search />} />
        <Route path='/articles' element={<Articles />} />
        <Route path='/articles/:id' element={<ArticleDetail />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/list-for-1percent' element={<SellingPage />} />
        <Route path='/commission-explained' element={<CommissionExplained />} />
        {/* Change city-specific route to /city/:city */}
        <Route path='/city/:city' element={<CityPages />} />
        <Route path='/thank-you' element={<ThankYou />} />
        <Route path='/listings' element={<Listings />} />
        
        

        <Route path='/listing/:ml_num/:address' element={<ListingDetail />} />
        <Route path='/wishlist' element={<Wishlist />} />
        <Route path='/login' element={<Login />} />
        <Route path='/register' element={<Register />} />
        <Route path='/register/activate/:token' element={<ActivateAccount />} />
        <Route path='/forgot-password' element={<ForgotPassword />} />
        <Route path='/users' element={<Users />} />
        <Route path='/add-article' element={<AddArticle />} />
        <Route path='*' element={<Navigate to='/' />} />
      </Routes>
    </div>
  );
}

function App() {
  return (
    <Router>
      <AuthProvider>
        <AppContent />
      </AuthProvider>
    </Router>
  );
}

export default App;
